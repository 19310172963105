<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
          <button class="team-update-button" @click="getRankingData">
            更新
          </button>
        </div>
      </header>
      <div class="reserve-select-container">
        <h3 class="title">ランキング情報</h3>
        <div class="bars-container">
          <h5 class="arrow-button" v-on:click="goToMoveMonth(-1)">＜前月</h5>
          <h3 class="title nowrap">[{{ print_dwdt }}]</h3>
          <h5 class="arrow-button" v-on:click="goToMoveMonth(1)">次月＞</h5>
        </div>
        <div class="bars-container">
          <div class="bar nowrap" v-on:click="changeRanking(1)">
            <p>来店回数</p>
          </div>
          <div class="bar nowrap" v-on:click="changeRanking(2)">
            <p>キル回数</p>
          </div>
          <div class="bar nowrap" v-on:click="changeRanking(3)">
            <p>K/D比</p>
          </div>
        </div>
        <div class="list-container">
          <table class="list-container">
            <tr>
              <th style="width: 10%">No.</th>
              <th style="width: 60%">プレイヤー名</th>
              <th style="width: 30%">{{ list_title[print_list_kbn - 1] }}</th>
            </tr>
            <tr
              v-for="data in list_ranking_data"
              :class="{ highlight: data.rank <= 3 }"
              :key="data.nmemno"
            >
              <td>
                <template v-if="data.rank === 1">
                  <img class="img" src="images/ranking_1.svg" />
                </template>
                <template v-else-if="data.rank === 2">
                  <img class="img" src="images/ranking_2.svg" />
                </template>
                <template v-else-if="data.rank === 3">
                  <img class="img" src="images/ranking_3.svg" />
                </template>
                <template v-else>{{ data.rank }}</template>
              </td>
              <td>{{ data.vplynm }}</td>
              <template v-if="print_list_kbn === 1">
                <td>
                  {{ data.count_visit }}
                </td>
              </template>
              <template v-else-if="print_list_kbn === 2">
                <td>
                  {{ data.sum_kill }}
                </td>
              </template>
              <template v-else-if="print_list_kbn === 3">
                <td>
                  {{ data.sum_kd }}
                </td>
              </template>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,
      print_list_kbn: null,
      print_dwdt: null,
      search_dwdt: null,
      list_ranking_data: [],
      list_title: ["来店回数", "キル回数", "K/D比"],
    };
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
        await this.getRankingFirst();
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getRankingFirst() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");

      this.print_dwdt = `${year}年${parseInt(month)}月`;
      this.search_dwdt = `${year}${month}01`;
      this.print_list_kbn = 1;

      this.getRankingData();
    },
    // 戻る
    back() {
      this.$router.push("/my-page");
    },
    async changeRanking(kbn) {
      const year = parseInt(this.search_dwdt.slice(0, 4));
      const month = String(parseInt(this.search_dwdt.slice(4, 6))).padStart(
        2,
        "0"
      );

      this.print_dwdt = `${year}年${parseInt(month)}月`;
      this.search_dwdt = `${year}${month}01`;
      this.print_list_kbn = kbn;

      this.getRankingData();
    },
    async goToMoveMonth(fluctuation) {
      let year = parseInt(this.search_dwdt.slice(0, 4));
      let month = String(parseInt(this.search_dwdt.slice(4, 6))).padStart(
        2,
        "0"
      );

      const newDate = new Date(year, month - 1, 1);
      newDate.setMonth(newDate.getMonth() + fluctuation);
      year = newDate.getFullYear();
      month = String(newDate.getMonth() + 1).padStart(2, "0");

      this.print_dwdt = `${year}年${parseInt(month)}月`;
      this.search_dwdt = `${year}${month}01`;

      this.getRankingData();
    },
    async getRankingData() {
      try {
        // APIリクエストを実行
        const response = await APIClient.get("getRankingData.php", {
          dwdt: `${this.search_dwdt}`,
          listSelect: `${this.print_list_kbn}`,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.list_ranking_data = response.Data;
        } else {
          console.error("ランキングの取得に失敗しました。");
        }
      } catch (error) {
        console.error("ランキングの取得に失敗しました。", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.back,
.arrow-button {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover,
.arrow-button:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

.reserve-select-container {
  margin-top: 20px;
  justify-content: center;
}

.bars-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  justify-content: center;
}

.bar {
  background-color: white;
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.bar p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}

.list-container {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.list-container tr th,
.list-container tr td {
  background-color: black;
  color: white;
  padding: 5px;
  text-align: center;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}
.highlight td {
  color: yellow !important;
  font-weight: bold;
}

.img {
  width: 100%; /* 画像の幅を調整 */
  height: auto;
}
</style>
