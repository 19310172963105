<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="setting-kill-container">
        <div class="yellow-base-img" style="position: relative">
          <img src="images/record_base.svg" />
          <div class="overlay-text">
            <p>[キル数入力]</p>
          </div>
        </div>
        <form @submit.prevent="submitBooking" class="kill-setting-form">
          <div class="form-group">
            <label for="dwdt" class="label-left">開催日</label>
            <!-- 開催日のセレクトボックス -->
            <select id="dwdt" v-model="dwdt" required>
              <option disabled value="">選択してください</option>
              <option v-for="date in availableDates" :key="date">
                {{ date }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="ngmno" class="label-left">開催回数</label>
            <!-- 開催回数のセレクトボックス -->
            <select id="ngmno" v-model="ngmno" required>
              <option disabled value="">選択してください</option>
              <option
                v-for="option in availableCounts"
                :key="option.count"
                :value="option.count"
              >
                {{ option.display }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="nkill" class="label-left">キル数</label>
            <div class="form-count">
              <input
                type="number"
                id="nkill"
                v-model="nkill"
                min="0"
                max="999"
                :disabled="!ngmno"
                required
              /><span style="color: white"> 回</span>
            </div>
          </div>
          <div class="form-group">
            <label for="nkill" class="label-left">デス数</label>
            <div class="form-count">
              <input
                type="number"
                id="ndeath"
                v-model="ndeath"
                min="0"
                max="999"
                :disabled="true"
                required
              /><span style="color: white"> 回</span>
            </div>
          </div>
          <div v-if="confirmDialog" class="modal">
            <div class="modal-content">
              <p>登録を実行しますか？</p>
              <button @click="executeUpdate">はい</button>
              <button @click="toggleConfirmDialog">いいえ</button>
            </div>
          </div>
          <div v-if="resultDialog" class="modal">
            <div class="modal-content">
              <div v-html="resultMessage"></div>
              <button @click="resultOKButton">OK</button>
            </div>
          </div>
          <button type="submit" class="reserve-button">登録する</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,

      dwdt: null,
      ngmno: null,
      nkill: 0,
      ndeath: 0,

      confirmDialog: false,
      resultDialog: false,
      resultMessage: null,
      success: false,

      loadedData: [],
    };
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        this.getMemberInfo(sessionUserId, login_hash, login_time);
        this.getKillInfo(sessionUserId);
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getKillInfo(vid) {
      try {
        const response = await APIClient.get("get_kill_results.php", {
          vid: vid,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.loadedData = response.Data;
        } else {
          console.error("情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("情報の取得に失敗しました。", error);
      }
    },

    async submitBooking() {
      // ダイアログが表示されている場合は何もしない
      if (this.confirmDialog) return;

      // ダイアログを表示
      this.toggleConfirmDialog();
    },
    // 仮予約ダイアログの表示切り替え
    toggleConfirmDialog() {
      this.confirmDialog = !this.confirmDialog;
    },
    // 結果ダイアログの表示切り替え
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },
    // resultでのOKボタン押下
    resultOKButton() {
      this.toggleResultDialog();
      if (this.success) {
        this.back();
      }
    },
    // 登録の実行
    async executeUpdate() {
      try {
        const response = await APIClient.get("set_kill_results.php", {
          nkill: this.nkill,
          dwdt: this.dwdt,
          ngmno: this.ngmno,
          vid: this.vid,
        });

        console.log(response); // レスポンスをログに出力
        if (response.Status == API_STATUS.NORMAL) {
          this.resultMessage = "設定が完了しました。";
          this.success = true;
        } else if (response.Status == API_STATUS.ALERT) {
          this.resultMessage = response.Message;
        } else {
          this.resultMessage = "エラーが発生しました。";
        }
      } catch (error) {
        console.error(error); // エラーをログに出力
        // ここでエラー時の処理を追加
        this.resultMessage = "エラーが発生しました。";
      } finally {
        // ダイアログを切替
        this.confirmDialog = false;
        this.toggleResultDialog();
      }
    },
    // 戻る
    back() {
      const url = "/battle-record";
      this.$router.push(url);
    },
  },
  computed: {
    // 選択可能な開催日の配列
    availableDates() {
      return Array.from(new Set(this.loadedData.map((item) => item.dwdt)));
    },
    // 現在選択されている開催日と合致する開催回数の配列
    availableCounts() {
      if (!this.dwdt) return [];
      return this.loadedData
        .filter((item) => item.dwdt == this.dwdt)
        .map((item) => ({
          count: item.ngmno,
          display: `${item.ngmno}回 ${item.nstrtim}開催`,
        }));
    },
  },
  watch: {
    dwdt: function () {
      this.ngmno = null;
    },

    // 開催回数が変更された場合に、キル数を更新する
    ngmno: function (newVal) {
      // 選択されている開催日と開催回数に合致するloadedDataのオブジェクトを取得
      const selectedData = this.loadedData.find(
        (item) => item.dwdt == this.dwdt && item.ngmno == newVal
      );
      // オブジェクトが存在すれば、キルデス数を設定
      if (selectedData) {
        this.nkill = selectedData.nkill;
        this.ndeath = selectedData.ndeath;
      } else {
        // オブジェクトが存在しない場合は、0に設定
        this.nkill = 0;
        this.ndeath = 0;
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.setting-kill-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 80%;
}
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

button {
  max-width: 300px;
  width: 95%;
}

p {
  font-size: 15px;
  font-weight: bold;
}

.form-count {
  display: flex;
  align-items: center;
}

.form-count input {
  flex: 1;
  margin-right: 5px;
  width: calc(100% - 25px);
  margin-left: 8px;
  text-align: right;
}

.form-count span {
  width: 20px; /* テキストの幅を設定 */
  color: white; /* テキストの色を設定 */
}

#ndeath:disabled {
  background-color: #e0e0e0;
}
</style>
