<template>
  <header>
    <div class="header-elements">
      <h4 class="back" @click="goback">＜ 戻る</h4>
    </div>
  </header>
  <div class="gallery">
    <div class="gallery-item">
      <img src="/images/IMG_1391.jpg" alt="" />
      <h2>2024.9.4【物販品】</h2>
      <p></p>
      <a
        href="https://photos.google.com/share/AF1QipNbDOUQNrguahIwOViu_TrMHb1PH9AbPkVvws58e7PYskib-Os5iusJBcHJjj4Flw?key=TXpVMFdyRjNDRGpfckMwLUVDYUt5bmxDQWhuWnBR"
        target="_blank"
        ><button class="content-button">写真閲覧はこちら</button></a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "PhotoGallery",
  methods: {
    goback() {
      this.$router.push("/");
    },
    goDiv(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
  mounted() {},
};
</script>

<style scoped>
.header-elements {
  text-align: left; /* 左寄せに設定 */
  padding: 10px; /* 必要に応じてパディングを追加 */
}

.back {
  display: inline; /* テキストの下だけに線が出るように設定 */
  cursor: pointer; /* カーソルをポインターに変更 */
}

.back:hover {
  border-bottom: 1px solid black;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.gallery-item {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  width: 400px;
}

.gallery-item img {
  height: 100%;
  max-height: 300px;
  height: auto;
}

.view-photos {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .gallery-item {
    width: 60%;
    height: auto;
  }
}
</style>
