<template>
  <div class="qrcode-container">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 200,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    // QRコードを描画する
    this.drawQRCode();
  },
  watch: {
    // codeプロパティが変更された場合、再描画する
    code() {
      this.drawQRCode();
    },
    size() {
      // サイズが変更された場合も再描画する
      this.drawQRCode();
    },
  },
  methods: {
    async drawQRCode() {
      if (!this.code) {
        return;
      }

      try {
        await QRCode.toCanvas(this.$refs.canvas, this.code, this.options);
      } catch (error) {
        console.error("QRコードの生成中にエラーが発生しました。", error);
      }
    },
  },
};
</script>

<style scoped>
.qrcode-container {
  display: flex;
  justify-content: center;
}

canvas {
  /* Canvasのサイズをstyleで設定 */
  width: 300px !important; /* デフォルト値 */
  height: 300px !important; /* デフォルト値 */
}
</style>
