<template>
  <div class="mypage">
    <div class="container">
      <div class="login-container">
        <h3 class="title">ログイン</h3>
        <form @submit.prevent="login" class="login-form">
          <div class="form-group">
            <label for="userId" class="label-left">会員ID</label>
            <input type="text" id="userId" v-model="userId" required />
          </div>
          <div class="form-group">
            <label for="password" class="label-left">パスワード</label>
            <input type="password" id="password" v-model="password" required />
          </div>
          <div class="form-group-checkbox">
            <input type="checkbox" id="rememberMe" v-model="rememberMe" />
            <label for="rememberMe">会員ID・パスワードを保存する</label>
          </div>
          <div class="form-group">
            <button type="submit" class="login-button">ログインする</button>
            <button type="button" class="back-button" @click="goBack">
              戻る
            </button>
          </div>
          <div v-if="resultDialog" class="modal">
            <div class="modal-content">
              <div v-html="resultMessage"></div>
              <button @click="toggleResultDialog">OK</button>
            </div>
          </div>
        </form>
        <p>
          <a
            @click="goRegistration"
            style="color: white; cursor: pointer; text-decoration: underline"
            >会員の新規登録はこちら</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      userId: "",
      password: "",
      rememberMe: false,
      resultDialog: false,
      resultMessage: null,
      deviceToken: null, // 追加: デバイストークン用
    };
  },
  mounted() {
    // ローカルストレージから情報を取得して入力欄に設定
    this.populateLoginFields();
    // デバイストークンの取得
    this.deviceToken = getQueryParam("device_token");
    if (this.deviceToken && getQueryParam("type") == 1) {
      this.deviceToken = "<" + this.deviceToken + ">";
    }
  },
  methods: {
    // ローカルストレージから情報を取得して入力欄に設定するメソッド
    populateLoginFields() {
      const savedUserId = localStorage.getItem("savedUserId");
      const savedPassword = localStorage.getItem("savedPassword");
      if (savedUserId && savedPassword) {
        this.userId = savedUserId;
        this.password = savedPassword;
        this.rememberMe = true; // チェックボックスをチェック済みにする
      }
    },
    // デバイストークンをAPIに渡すメソッド
    async registerDeviceToken(token, nmemno) {
      try {
        const response = await APIClient.post("SendDeviceToken.php", {
          DeviceToken: token,
          MemNo: nmemno,
          NfldNo: 1,
        });

        if (response.Status == API_STATUS.NORMAL) {
          console.log("デバイストークンを保存できました！");
        } else {
          console.error("デバイストークンの保存に失敗しました。");
        }
      } catch (error) {
        console.error("デバイストークンの保存中にエラーが発生しました:", error);
      }
    },
    // ログイン情報を保存するメソッド
    saveLoginInfo() {
      if (this.rememberMe) {
        localStorage.setItem("savedUserId", this.userId);
        localStorage.setItem("savedPassword", this.password);
      } else {
        localStorage.removeItem("savedUserId");
        localStorage.removeItem("savedPassword");
      }
    },
    async login() {
      try {
        const response = await APIClient.get("login.php", {
          vid: this.userId,
          vpw: this.password,
        });

        if (response.Status == API_STATUS.NORMAL) {
          localStorage.setItem("sessionUserId", this.userId);
          localStorage.setItem("login_hash", response.Data.login_hash);
          localStorage.setItem("login_time", response.Data.login_time);

          // デバイストークンをAPIに渡す
          if (this.deviceToken) {
            this.registerDeviceToken(this.deviceToken, response.Data.nmemno);
          }

          const bookingURL = "/#/my-page";
          window.location.href = bookingURL;
        } else if (
          response.Status == API_STATUS.ALERT ||
          response.Status == API_STATUS.NODATA
        ) {
          this.resultMessage = response.Message;
          this.toggleResultDialog();
        } else {
          this.resultMessage = "エラーが発生しました。";
          this.toggleResultDialog();
        }

        // ログイン情報を保存
        this.saveLoginInfo();
      } catch (error) {
        console.error(error);
        this.toggleResultDialog();
      }
    },
    goBack() {
      const bookingURL = "/#/";
      window.location.href = bookingURL;
    },
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },
    goRegistration() {
      localStorage.removeItem("sessionUserId");
      const bookingURL = "/#/member-registration";
      window.location.href = bookingURL;
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group-checkbox {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.form-group-checkbox input[type="checkbox"] {
  margin-right: 8px;
}

.back-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
  background-color: white;
  color: black;
}

@media (max-width: 1000px) {
  .back-button {
    display: none;
  }
}
</style>
