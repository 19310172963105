<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
          <button class="team-update-button" @click="getTeamList">更新</button>
        </div>
      </header>
      <div class="reserve-select-container">
        <h3 class="title">チーム割り振り[{{ today }}]</h3>
        <div
          v-for="(team, index) in list_team"
          :key="team.ntmno"
          class="team-container"
        >
          <h4
            :class="index % 2 == 0 ? 'team-red-display' : 'team-yellow-display'"
          >
            {{ team.vtmnm }}
          </h4>
          <ul>
            <li
              v-for="member in team.members"
              :key="member.vid"
              :class="member.vid == vid ? 'team-member-self' : ''"
            >
              {{ member.vplynm }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,
      list_team: [],
    };
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
        await this.getTeamList();
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getTeamList() {
      try {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");

        // APIリクエストを実行
        const response = await APIClient.get("get_team_info.php", {
          dwdt: `${year}${month}${day}`,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.list_team = response.Data;
        } else {
          console.error("チーム一覧の取得に失敗しました。");
        }
      } catch (error) {
        console.error("チーム一覧の取得に失敗しました。", error);
      }
    },
    // 戻る
    back() {
      this.$router.push("/my-page");
    },
  },
  computed: {
    today() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}年${month}月${day}日`;
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

.reserve-select-container {
  margin-top: 20px;
}

.team-container {
  margin-bottom: 20px;
}

.team-container h4 {
  margin: 0;
  padding: 10px;
  text-align: center;
}

.team-red-display {
  background-color: red;
  color: #fff;
}

.team-yellow-display {
  background-color: yellow;
  color: black;
}

.team-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.team-container ul li {
  background-color: black;
  color: white;
  padding: 5px;
  text-align: center;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.team-member-self {
  color: yellow !important;
  font-weight: bolder;
}

.team-update-button {
  background-color: #fff;
}
</style>
