<template>
  <div class="mypage">
    <header>
      <div class="header-elements">
        <h4 class="back" @click="back">＜ 戻る</h4>
        <label>{{ vid }}：{{ vplynm }}</label>
      </div>
    </header>
    <div class="container">
      <div class="record-container">
        <h4 style="margin-top: 50px">[QRコード]</h4>
        <QrCodeRenderer
          :code="qrCodeContent"
          :size="300"
          class="qr-code"
        ></QrCodeRenderer>
        <h5>{{ updatedTime }}更新</h5>
        <h4 class="title">このコードを店頭でお見せください</h4>
        <div class="button-group">
          <button type="button" @click="updateTime">
            時刻を更新して再読込
          </button>
          <button type="button" @click="back" class="back-button">戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import QrCodeRenderer from "@/components/QrCodeRenderer.vue";
import { pad, checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  components: {
    QrCodeRenderer,
  },
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,
      qrCodeContent: null,
      updatedTime: null,
    };
  },
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        this.getMemberInfo(sessionUserId, login_hash, login_time);
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;

          this.updateTime();
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },

    // 時間更新
    updateTime() {
      // QRコードの内容を設定
      const now = new Date();
      const formattedDate = `${now.getFullYear()}/${pad(
        now.getMonth() + 1
      )}/${pad(now.getDate())} ${pad(now.getHours())}:${pad(
        now.getMinutes()
      )}:${pad(now.getSeconds())}`;
      this.qrCodeContent = `${this.vid} ${formattedDate}\n`;
      this.updatedTime = formattedDate;
    },

    // 戻る
    back() {
      this.$router.push("/my-page");
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
h5,
h4,
h3,
h2 {
  color: white;
}

.record-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 700px;
  width: 80%;
}

.record-image {
  width: 100%; /* 画像を幅いっぱいに表示 */
  height: auto; /* アスペクト比を保持しつつ高さを自動調整 */
  margin-bottom: 20px;
}

.record-details {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #414141; /* record-detailsの下にラインを追加 */
}

.record-element {
  padding: 10px 0 10px 0;
  display: flex;
  color: white;
  justify-content: center;
  border-bottom: 1px solid #414141; /* record-detailsの下にラインを追加 */
}

.record-element span {
  width: 100px;
  text-align: right;
  padding: 0;
  font-weight: bold;
}

.record-data {
  font-weight: bold;
  color: yellow;
}

.qr-code {
  max-width: 500px;
  margin: 0 auto 20px auto;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  max-width: 300px;
  width: 95%;
}

.back-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
  background-color: white;
  color: black;
}
</style>
