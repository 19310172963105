<template>
  <div class="width-100">
    <div class="container-calendar">
      <div class="button-container-calendar">
        <div class="col" style="width: 25%; float: left">
          <button
            style="background-image: url('images/calendar_pre.svg')"
            id="previous"
            v-on:click="previous()"
          ></button>
        </div>

        <p
          class="sub-title"
          style="
            margin-bottom: 0px;
            width: 50%;
            float: left;
            text-align: center;
          "
          id="monthAndYear"
        ></p>
        <div class="col" style="width: 25%; float: right">
          <button
            style="background-image: url('images/calendar_next.svg')"
            id="next"
            v-on:click="next()"
          ></button>
        </div>
      </div>

      <table class="table-calendar" id="calendar">
        <thead id="thead-month"></thead>
        <tbody id="calendar-body"></tbody>
      </table>
    </div>
  </div>

  <!-- モーダルのHTML -->
  <div id="myModal" class="modal">
    <div class="modal-content">
      <span class="close">&times;</span>
      <h2 id="modal-date"></h2>
      <button
        class="content-button"
        style="margin-bottom: 10px"
        @click="goToBooking"
        v-if="!isPastDate"
      >
        予約する
      </button>
      <ul id="modal-reservations"></ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ServerInfo from "../server-info";
export default {
  name: "MyCalendar",
  props: {
    fromMyPage: Boolean,
  },
  data() {
    return {
      today: "",
      currentMonth: "",
      currentYear: "",
      baseUrl: ServerInfo.baseUrl,
      popupItems: [],
      modalDate: "", // モーダルに表示する日付
      modalReservations: [], // モーダルに表示する予約情報
      selectedDate: "",
    };
  },
  methods: {
    getEventData(month, year) {
      axios
        .get(this.baseUrl + "get_eigyolist.php?dwdt=null&ndel=0")
        .then((response) => {
          this.$emit("calendar-item-loaded", month, year, response.data.Data);
          this.showCalendar(month, year, response.data.Data);
          this.getReserveCount();
          this.getVisitCount();
        })
        .catch((error) => {
          console.log(error);
          //alert("開催日データを取得できません！");
        });
    },
    getReserveCount() {
      // 追加
      axios
        .get(this.baseUrl + "get_reserve_count.php")
        .then((response) => {
          this.addReserveButtons(response.data.Data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addReserveButtons(reserveData) {
      if (!reserveData || !Array.isArray(reserveData)) return;
      // 追加
      reserveData.forEach((item) => {
        let s = item.drsvdt.toString();
        let y = parseInt(s.substr(0, 4));
        let m = parseInt(s.substr(4, 2)) - 1; // 月は0から始まるため-1
        let d = parseInt(s.substr(6, 2));

        if (y === this.currentYear && m === this.currentMonth) {
          let cell = document.querySelector(
            `td[data-date='${d}'][data-month='${m + 1}'][data-year='${y}']`
          );
          if (cell) {
            let button = document.createElement("button");
            button.classList.add("blue-button");
            button.innerText = item.nnum;
            cell.appendChild(button);
          }
        }
      });
    },
    getVisitCount() {
      // 追加
      axios
      .get(this.baseUrl + "get_visit_count.php")
        .then((response) => {
          this.addVisitButtons(response.data.Data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addVisitButtons(reserveData) {
      if (!reserveData || !Array.isArray(reserveData)) return;
      // 追加
      reserveData.forEach((item) => {
        let s = item.dwdt.toString();
        let y = parseInt(s.substr(0, 4));
        let m = parseInt(s.substr(4, 2)) - 1; // 月は0から始まるため-1
        let d = parseInt(s.substr(6, 2));

        if (y === this.currentYear && m === this.currentMonth) {
          let cell = document.querySelector(
            `td[data-date='${d}'][data-month='${m + 1}'][data-year='${y}']`
          );
          if (cell) {
            let button = document.createElement("button");
            button.classList.add("blue-button");
            button.innerText = item.nmemno + '名来店中';
            cell.appendChild(button);
          }
        }
      });
    },
    showModal(year, month, day) {
      this.selectedDate = `${year}${String(month).padStart(2, "0")}${String(
        day
      ).padStart(2, "0")}`;

      axios
        .get(`${this.baseUrl}get_reserve_info.php?drsvdt=${this.selectedDate}`)
        .then((response) => {
          let reservations = response.data.Data;
          this.modalDate = `${year}年${String(month).padStart(
            2,
            "0"
          )}月${String(day).padStart(2, "0")}日`;
          this.modalReservations = reservations;
          this.displayModal();
        })
        .catch((error) => {
          console.log(error);
          alert("予約情報を取得できませんでした！");
        });
    },
    displayModal() {
      let modal = document.getElementById("myModal");
      let span = document.getElementsByClassName("close")[0];
      let modalDateElement = document.getElementById("modal-date");
      let modalReservationsElement =
        document.getElementById("modal-reservations");

      // モーダルの内容を設定
      modalDateElement.innerText = this.modalDate;
      modalReservationsElement.innerHTML = "";
      this.modalReservations.forEach((reservation) => {
        let listItem = document.createElement("li");
        listItem.innerText = `${reservation.ndmemnm}：${reservation.nnum}人  【${reservation.ngame}】来店予定時刻(${reservation.nentry})`;
        modalReservationsElement.appendChild(listItem);
      });

      // モーダルを表示
      modal.style.display = "block";
      document.body.classList.add("modal-open"); // スクロールを無効化

      // モーダルを閉じる処理
      if (!span.onclick) {
        span.onclick = () => {
          modal.style.display = "none";
          document.body.classList.remove("modal-open"); // スクロールを有効化
        };
      }

      if (!window.onclick) {
        window.onclick = (event) => {
          if (event.target === modal) {
            modal.style.display = "none";
            document.body.classList.remove("modal-open"); // スクロールを有効化
          }
        };
      }
    },
    goToBooking() {
      document.body.classList.remove("modal-open"); // スクロールを有効化
      this.$emit(
        "go-to-reserve",
        `${this.selectedDate.slice(0, 4)}-${this.selectedDate.slice(
          4,
          6
        )}-${this.selectedDate.slice(6, 8)}`
      );
    },
    next() {
      this.currentYear =
        this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
      this.currentMonth = (this.currentMonth + 1) % 12;
      this.getEventData(this.currentMonth, this.currentYear);
    },

    previous() {
      this.currentYear =
        this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
      this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
      this.getEventData(this.currentMonth, this.currentYear);
    },

    jump() {
      this.currentYear = parseInt(this.selectYear.value);
      this.currentMonth = parseInt(this.selectMonth.value);
      this.getEventData(this.currentMonth, this.currentYear);
    },

    addkaigyo(text) {
      var result = "";
      var arr = text.match(/.{1,14}/g);
      for (var i = 0; i < arr.length; i++) {
        if (result.length == 0) result += arr[i];
        else result += "<br/>" + arr[i];
      }
      console.log(result);
      return result;
    },

    async showCalendar(month, year, calendarItems) {
      var monthAndYear = document.getElementById("monthAndYear");
      var calendar = document.getElementById("calendar");
      var lang = calendar.getAttribute("data-lang");

      var months = "";
      var days = "";

      var monthDefault = [
        "１月",
        "２月",
        "３月",
        "４月",
        "５月",
        "６月",
        "７月",
        "８月",
        "９月",
        "１０月",
        "１１月",
        "１２月",
      ];

      var dayDefault = ["日", "月", "火", "水", "木", "金", "土"];

      if (lang == "en") {
        months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      } else if (lang == "id") {
        months = [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember",
        ];
        days = ["Ming", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];
      } else if (lang == "fr") {
        months = [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ];
        days = [
          "dimanche",
          "lundi",
          "mardi",
          "mercredi",
          "jeudi",
          "vendredi",
          "samedi",
        ];
      } else {
        months = monthDefault;
        days = dayDefault;
      }

      var $dataHead = "<tr>";
      for (var dhead in days) {
        $dataHead +=
          "<th class='day-tag' data-days='" +
          days[dhead] +
          "'>" +
          days[dhead] +
          "</th>";
      }
      $dataHead += "</tr>";

      document.getElementById("thead-month").innerHTML = $dataHead;
      var firstDay = new Date(year, month).getDay();

      var tbl = document.getElementById("calendar-body");

      tbl.innerHTML = "";

      monthAndYear.innerHTML =
        this.convertToFull(year.toString()) + "年 " + months[month];
      // creating all cells
      var date = 1;
      for (var i = 0; i < 6; i++) {
        var row = document.createElement("tr");

        for (var j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            var cell = document.createElement("td");
            var cellText = document.createTextNode("");
            cell.appendChild(cellText);
            row.appendChild(cell);
          } else if (date > this.daysInMonth(month, year)) {
            break;
          } else {
            cell = document.createElement("td");
            cell.setAttribute("data-date", date);
            cell.setAttribute("data-month", month + 1);
            cell.setAttribute("data-year", year);
            cell.setAttribute("data-month_name", months[month]);
            cell.className = "date-picker tooltip-wrap";
            cell.innerHTML = "<p class='date-text'>" + date + "</p>";
            if (calendarItems != null) {
              if (!Array.isArray(calendarItems)) {
                var arr = Array();
                arr.push(calendarItems);
                calendarItems = arr;
              }
              cellText = "";
              calendarItems.forEach((item) => {
                var s = item.dwdt.toString();
                var y = parseInt(s.substr(0, 4));
                var m = parseInt(s.substr(4, 2));
                var d = parseInt(s.substr(6, 2));
                if (d == date && m == month + 1 && y == year) {
                  if(item.vevt.length < 50){
                  cellText +=
                    "<div class = 'calendar-item'><p class = 'calendar-item-text' style='font-size: 15px;'>" +
                      this.addkaigyo(item.vevt) + 
                    "</p></div>";
                  }else{
                  cellText +=
                    "<div class = 'calendar-item'><p class = 'calendar-item-text' style='font-size: 13px;'>" +
                      this.addkaigyo(item.vevt) + 
                    "</p></div>";
                  }
                } 
              });
              if(cellText == ""){
                if(j > 0 && j < 6){
                cellText = 
                    "<div class = 'calendar-item'><p class = 'calendar-item-text'>" +
                    "サバゲー・ナーフ</p><p class = 'calendar-item-text'>" +
                      "サバゲー(10歳以上)・ちょいサバDay</p><p class = 'calendar-item-text'>" +
                    "前半13:00~16:30</p><p class = 'calendar-item-text'>"+
                    "後半17:30~21:00"+
                    "</p></div>";
                  } else{
                cellText = 
                    "<div class = 'calendar-item'><p class = 'calendar-item-text'>" +
                      "1Fサバゲー2FナーフDay</p><p class = 'calendar-item-text'>" +
                    "9:00~16:30</p></div>";
                  }
              }
              cell.innerHTML += cellText;
              if (this.popupItems != null && this.popupItems.length > 0) {
                var innerHtmlText = "";

                this.popupItems.forEach((item) => {
                  innerHtmlText +=
                    "<li>" +
                    "<div class='row'><p>" +
                    item.Date +
                    " " +
                    item.Time +
                    "</p></div>" +
                    "<div class='row'><p>" +
                    item.Title +
                    ": " +
                    item.Detail +
                    "</p></div>" +
                    "</li>";
                });
                var positionText =
                  (i > 2 ? " top" : " bot") + (j > 3 ? " left" : " right");
                cell.innerHTML +=
                  "<div class='tooltip-content" +
                  positionText +
                  "'>" +
                  "<ul style='list-style-type: none;'>" +
                  innerHtmlText +
                  "</ul></div>";
                this.popupItems = [];
              }
            }
            if (
              date === this.today.getDate() &&
              year === this.today.getFullYear() &&
              month === this.today.getMonth()
            ) {
              cell.className = "date-picker selected";
            }
            row.appendChild(cell);

            // クロージャを使ってクリックイベント内のdateを閉じ込める
            (function (y, m, d) {
              cell.addEventListener("click", () => {
                this.showModal(y, m, d);
              });
            }).call(this, year, month + 1, date);

            date++;
          }
        }

        tbl.appendChild(row);
      }
    },

    daysInMonth(iMonth, iYear) {
      return 32 - new Date(iYear, iMonth, 32).getDate();
    },
    convertToFull(e) {
      return e.replace(/[!-~]/g, (fullwidthChar) =>
        String.fromCharCode(fullwidthChar.charCodeAt(0) + 0xfee0)
      );
    },
  },
  computed: {
    isPastDate() {
      const date = new Date(
        this.selectedDate.slice(0, 4),
        this.selectedDate.slice(4, 6) - 1,
        this.selectedDate.slice(6, 8)
      );
      return date < new Date().setHours(0, 0, 0, 0);
    },
  },
  mounted() {
    this.today = new Date();
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.getEventData(this.currentMonth, this.currentYear);
  },
};
</script>
<style>
.wrapper {
  max-width: 1100px;
}

.container-calendar {
  background: #ffffff;
  max-width: 80%;
  margin: 0 auto;
  overflow: auto;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: top;
  height: 100px;
  text-align: right;
  min-width: 120px;
}

.date-picker.selected {
  font-weight: bold;
  outline: 1px dashed #00bcd4;
}

.date-picker.selected span {
  border-bottom: 2px solid currentColor;
}

/* friday
    .date-picker:nth-child(6) {
    color: green;
    } */

#monthAndYear {
  text-align: center;
  margin-top: 0;
}

.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}

.button-container-calendar button {
  height: 45px;
  background-size: contain;
  border: none;
  width: 80px;
  background-repeat: no-repeat;
  display: inline;
}

.blue-button {
  background-color: #77bbff; /* 薄めの青色 */
  width: 100%; /* 横幅いっぱいに伸びる */
  border: 1px solid #0056b3; /* 濃いめの青枠 */
  border-radius: 5px; /* 角に丸みを持たせる */
  color: white;
  min-height: 25px; /* ボタンの高さ */
  height: 15%; /* ボタンの高さ */
  background-image: none; /* 既存の背景画像を無効化 */
  display: inline-block; /* ボタンがinline要素であることを明示 */
  font-size: inherit; /* フォントサイズを継承 */
  cursor: pointer; /* カーソルをポインターに変更 */
}

#previous {
  float: left;
}

#next {
  float: right;
}

.footer-container-calendar {
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}

.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}
.day-tag {
  background-color: #f5f6fa;
  font-size: 15px;
  font-weight: normal;
  height: 20px !important;
  text-align: center !important;
}

.tooltip-wrap {
  position: relative;
}
.tooltip-content {
  display: none;
  position: absolute;
  background-color: #f2f2f2;
  padding: 0.5em;
  width: 200px;
  height: auto;
  text-align: left;
  font-size: 12px;
  z-index: 1;
}
.tooltip-wrap:hover .tooltip-content {
  display: block;
}

.top {
  top: -100%;
}

.bottom {
  top: 100%;
}

.left {
  left: -200px;
}

.right {
  left: 100%;
}

.date-text {
  margin-bottom: 10px;
  margin-top: 0px;
  text-align: right;
}

.calendar-item-text {
  margin-bottom: 0px;
  margin-top: 0px;
}

/* モーダルのスタイル */
.modal {
  display: none; /* デフォルトは非表示 */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* 黒い背景 */
}

body.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 5px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .container-calendar {
    background: #ffffff;
    max-width: 100%;
    margin: 0 auto;
    overflow: auto;
  }
  .day-tag {
    background-color: #f5f6fa;
    font-size: 9px;
    font-weight: normal;
    height: 9px !important;
    text-align: center !important;
  }
  .button-container-calendar button {
    height: 20px;
    background-size: contain;
    border: none;
    width: 40px;
    background-repeat: no-repeat;
    display: inline;
  }
  .table-calendar td,
  .table-calendar th {
    padding: 5px;
    border: 1px solid #e2e2e2;
    text-align: center;
    vertical-align: top;
    height: 20px;
    text-align: right;
    font-size: 9px;
    min-width: 6vw;
  }
}
</style>
