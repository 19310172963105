<template>
  <div class="mypage">
    <div class="container" v-if="initialized">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
        </div>
      </header>
      <div class="reserve-select-container">
        <h3 class="title">[来店予約]</h3>
        <div class="bars-container">
          <div class="bar" v-on:click="goToReserve">
            <p>新規予約</p>
          </div>
          <div v-if="hasReserve" class="bar" v-on:click="goToList">
            <p>マイ予約一覧</p>
          </div>
          <div v-if="!hasReserve" class="unavailable-bar">
            <p>マイ予約一覧</p>
          </div>
          <div class="bar" v-on:click="goToCalendar">
            <p>予約状況を見る</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,
      hasReserve: false,
      initialized: false,
    };
  },
  async mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    await this.initialize();
    this.initialized = true;
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        await this.getMemberInfo(sessionUserId, login_hash, login_time);
        await this.getReserveList(this.vid, login_hash, login_time);
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getReserveList(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_reserve_list.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.hasReserve =
            response.Data.future.length > 0 || response.Data.past.length > 0;
        } else {
          console.error("予約一覧の取得に失敗しました。");
        }
      } catch (error) {
        console.error("予約一覧の取得に失敗しました。", error);
      }
    },
    // 戻る
    back() {
      this.$router.push("/my-page");
    },
    goToReserve() {
      window.location.href = "/#/temporary-booking";
    },
    goToCalendar() {
      this.$router.push({
        name: "ReserveCalendar",
        params: { fromMyPage: 1 },
      });
    },
    goToList() {
      window.location.href = "/#/reserve-list";
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}

.bars-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
}

.bar {
  background-color: white;
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.unavailable-bar {
  background-color: rgba(255, 255, 255, 0.308);
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
}

.bar p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.unavailable-bar p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
