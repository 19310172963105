<template>
  <div>
    <a class="twitter-timeline" href="https://twitter.com/EWC_Whitecrow">
      Tweets by @EWC_Whitecrow
    </a>
  </div>
</template>

<script>
export default {
  name: "TwitterTimeline",
  mounted() {
    // Twitterのウィジェットスクリプトが既にロードされているかをチェック
    if (window.twttr) {
      this.loadTwitterWidget();
    } else {
      // スクリプトがまだロードされていない場合は、ロードする
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://platform.twitter.com/widgets.js";
      script.onload = this.loadTwitterWidget;
      document.head.appendChild(script);
    }
  },
  methods: {
    loadTwitterWidget() {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    },
  },
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
