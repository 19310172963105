// const BASEURL = "https://www.tokioka-ability-auction.com/tokioka/WebService/";
// const CCMAIL = "duongnp@rasis-soft.co.jp;nakatsuji@rasis-soft.co.jp;tokiokaability.22@gmail.com";
// const UPLOADFILEURL = "https://www.tokioka-ability-auction.com/tokioka/lp/testFileUpload";

const BASEURL = "https://ewc.rasis-soft.co.jp/api/";
const CCMAIL = "duongnp@rasis-soft.co.jp";
const UPLOADFILEURL = "https://www.tokioka-ability-auction.com/tokioka_dev2/lp/testFileUpload";
// const BEGINNERURL="http://localhost/#/beginner";
// const REGULATIONURL="http://localhost/#/regulation";
const BEGINNERURL="https://ewc.rasis-soft.co.jp/ewchomepage/#/beginner";
const REGULATIONURL="https://ewc.rasis-soft.co.jp/ewchomepage/#/regulation";

export default{
    baseUrl : BASEURL,
    cc_mail:CCMAIL,
    uploadFileUrl:UPLOADFILEURL,
    beginnerUrl:BEGINNERURL,
    regulationUrl:REGULATIONURL
}