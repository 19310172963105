<template>
  <div class="div14">
    <div class="sd content background-white height-auto">
      <div class="sd content-t3 height-auto width-80">
        <iframe
          class="margin-bottom-48"
          width="1250"
          height="703"
          src="https://www.youtube.com/embed/R_B7eTZ_zmU"
          title="E W C レギュレーション"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <p class="sd text-standard4 text font-1em margin-left-0">
          ご来店時の服装、装備について
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          迷彩服や装備品を着用したままの来場・外出・退場を禁止します。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          またエアソフトガン、及び装備品などその他のグッズについては、バッグ等に収納した状態で入退店してください。
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">BB弾速表</p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <img class="margin-bottom-48" src="images/bullet_speed.JPG" />
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          上記表は上限値となります。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          定例会では18歳以上用の6㎜機種のみ使用可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          8㎜の機種は使用不可です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36"
        >
          BB弾は0.2gまで使用可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ※上限値を超えた場合は如何なる場合でもゲームでは使用不可とします。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36"
        >
          ※法令の規定値（0.98J=0.20gの6mmBB弾で99m/s）以上の『準空気銃』の持ち込み禁止します。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          【使用できるエアガンについて】
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・BB弾は直径約6mmの重量0.20gまで使用可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・リキッドチャージ式ガスガンは
          マガジン温度が20℃〜35℃の範囲全てで初速条件を超えない範囲でご使用ください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・CO2ガスガンについてはSTGA(全日本トイガン安全協会)またはJASG（日本エアースポーツガン振興協同組合）で認定されたもののみ使用できます。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          外部ソースレギュレータは【サンプロジェクト製】についてのみ使用可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・外部ソースのレギュレーター開度は最大にて計測致します。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ・外部ソースにおける減圧機、減圧弁は使用不可、電磁弁使用不可、蓄圧式マガジン・エアータンク・ブースター缶は使用不可とします。
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          フィールドレギュレーション
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          エアソフトガン
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          フルオート(連射)・バースト(点射)射撃を禁止。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          （※セレクターがフル、バーストのみの場合は指切りで使用)
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          セミオート（単発）のみを可能とします。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          全身HIT制
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          自分自身で直撃か跳弾か判断出来ない場合でも全てHIT判定とします。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          （※明らかな跳弾はセーフとします。）
        </p>

        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          年齢制限
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・18歳未満の方が18歳以上対象のエアガンを使用することはできません。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・10歳未満の方はご遊戯いただけません。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          &emsp;※ナーフ戦など、10歳以下でもご遊戯いただける競技もあります。
        </p>

        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          禁止行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          フリーズコール※1、ブラインドファイア※2、トリックショット※３、ゾンビ行為※４、隙間撃ち※５、
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          障害物を押す行為や障害物に乗り上げる行為、ゲーム中のダッシュ、ジャンピングショット、その他危険行為、
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          他者への暴言、指摘行為、ゲーム内外において他者に対して、乱暴な言葉や傷つける言葉を口にする行為、
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          嘘のヒットコールからの騙し討ち行為、ヒット後味方チームへ相手チームの情報を伝える行為を禁止します。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ※1&emsp;フリーズコール…静かに忍び寄り手を上げさせる行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ※2&emsp;ブラインドファイア…障害物から銃だけを出して打つ行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ※3&emsp;トリックショット…わざと跳BB弾を狙ったり、銃を傾けBB弾の飛び方を曲げてヒットを狙う行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ※4&emsp;ゾンビ行為…BB弾が当たったにも関わらず、HITコールをしない行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          ※5&emsp;隙間撃ち…障害物などに銃を突っ込んで撃つ行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          その他行為等について
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          FF(味方撃ち)をした場合
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          &emsp;味方チームのプレイヤーを誤射してしまった場合、撃たれた側、撃った側両方がHITになります。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          報告に関して
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          &emsp;ゾンビ行為や上記のルール違反者を目撃した場合、当人同士のやりとりはせず、近くの速やかにスタッフへお知らせください。
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          その他の注意事項
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          FF(味方撃ち)をした場合
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          味方チームのプレイヤーを誤射してしまった場合や、撃たれた側、撃った側両方がHIT扱いになります。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          撃ってしまった側は必ず一言謝罪しましょう。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          報告に関して
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          ゾンビ行為や上記のルール違反者を目撃した場合、当人同士のやりとりはせず、速やかにスタッフへお知らせください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          使用出来ないもの
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・レーザーサイト
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・同時または瞬間的に同時発射可能なタイプのショットガン
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・モスカートやグレネードランチャーなど
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・鏡
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・過度に鋭利なアタッチメント類
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          ・ナイフ(ラバーナイフ含む)等のその他訓練用具
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          使用上気をつけるもの
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・光源機器(300ルーメン以下で尚且つ過度なストロボ機能の使用)
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          ・グレネード(ガス式、スプリング式の物、ダミーグレネード)
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ※グレネードについての注意事項
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          火薬を使用するものやCo２ガスを用いるもの、
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-36"
        >
          極めて大きな音や強い光を発するグレネードや煙が出るものは使用禁止とします。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          事故や盗難等について
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          E.W.C内での事故や怪我、盗難に関しまして当方は一切の責任等負い兼ねます。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          また、貴重品に関しても、自己管理とさせていただきます。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          危険行為、禁止事項に関わる退場について
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          上記に関して注意喚起に従わない方は、当施設への立ち入り不可とさせていただく場合があります。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          当施設利用時に危険行為や禁止事項に抵触し、施設スタッフにより退店を命じられた場合は返金不可とします。
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          セーフティエリアでの禁止事項について
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36"
        >
          セーフティエリアでは下記の行為を禁止しています。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・銃口を人に向ける行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・引き金に指をかける行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・空撃ち行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・マガジンを銃に装着する行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ・銃、装備品における安全装置の解除する行為
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          フィールド（戦闘エリア）への入退場について
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          入場時・入場中
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ゴーグル・マスクを必ず着用してください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          いかなる理由があってもゴーグル・マスクは絶対に外さないでください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          失明や歯が折れるといった重大な事故につながる恐れがあります。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          マガジンの装着はフィールドのみ可能です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          退場中
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ゲーム中、BB弾が身体に当たった場合は「ヒットコール」をし、挙手しながら出入口に向かってください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          フィールド出口にて、マガジンを外し所定の場所に3,4発空撃ちしてください。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          ※試射場退出時も同様です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ゴーグルに関して
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          使用可能なタイプ
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・フルフェイスタイプorゴーグル＋フェイスマスク
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          ・シューティンググラス(ガスケット有り)＋フェイスマスク
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          使用不可のタイプ
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・メッシュタイプのゴーグル
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24 margin-left-24"
        >
          ・シューティンググラス(ガスケット無し)
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          ※シューティンググラスを使われる場合はガスケットを使って隙間がない物を使用してください。
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          お客様同士のコミュニケーションについて
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36"
        >
          お客様同士での以下のような過度なコミュニケーションを禁止します。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・親しくない方へタメ口、高圧的な態度を取る行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・希望されていないのにも関わらず銃の使い方や戦い方を指導する行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・ゲーム中やゲーム終了後に高圧的に指示を出す行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          ・ナンパ、ハラスメント行為
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60"
        >
          ・いじめなどの行為
        </p>

        <p class="sd text-standard4 text font-1em margin-left-0">
          喫煙、飲酒について
        </p>
        <div class="full-line-gray margin-bottom-48 margin-top-12"></div>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          喫煙
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-36 margin-left-24"
        >
          所定の場所のみとしその他の場所では禁止です。
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-24"
        >
          飲酒
        </p>
        <p
          class="sd text-standard10 text-align-left font-16 text margin-bottom-60 margin-left-24"
        >
          飲酒状態でのゲーム参加、及びシューティングレンジなど全ての施設の利用を禁止します。酒類の持ち込みも禁止します。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegulationGuide",
  methods: {
    goback() {
      this.$router.push("/");
    },
  },
  mounted() {},
};
</script>
