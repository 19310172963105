<template>
  <div class="mypage">
    <div class="container">
      <div class="contact-container">
        <h3 class="title">参加申込み</h3>
        <form @submit.prevent="submitContact" class="contact-form">
          <div class="form-group">
            <label for="fullName" class="label-left">お名前</label>
            <input
              type="text"
              id="fullName"
              v-model="fullName"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label for="email" class="label-left">メールアドレス</label>
            <input
              type="email"
              id="email"
              v-model="email"
              maxlength="100"
              required
            />
          </div>
          <div class="form-group">
            <label for="phoneNumber" class="label-left">お電話番号</label>
            <input
              type="text"
              id="tel"
              v-model="tel"
              @input="validateTel"
              maxlength="20"
              required
            />
          </div>
          <div class="form-group">
            <label for="inquiry" class="label-left"
              >ご意見、ご質問があればご記入ください</label
            >
            <textarea
              id="inquiry"
              v-model="inquiry"
              rows="5"
              maxlength="2048"
              required
            ></textarea>
          </div>
          <div class="form-group">
            <button type="submit" class="submit-button">申し込む</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="confirmDialog" class="modal">
    <div class="modal-content">
      <p>下記の内容で送信しますか？</p>
      <br />
      <p>{{ "お名前：" + fullName }}</p>
      <p>{{ "メールアドレス：" + email }}</p>
      <p>{{ "お電話番号：" + tel }}</p>
      <p>{{ "ご意見、ご質問：\n" + inquiry }}</p>
      <button @click="executeInquiry">はい</button>
      <button @click="toggleConfirmDialog">いいえ</button>
    </div>
  </div>
  <div v-if="resultDialog" class="modal">
    <div class="modal-content">
      <div v-html="resultMessage"></div>
      <button @click="toggleResultDialog">OK</button>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";

export default {
  data() {
    return {
      fullName: null,
      email: null,
      tel: "",
      inquiry: null,

      confirmDialog: false,
      resultDialog: false,
      resultMessage: null,
    };
  },
  methods: {
    submitContact() {
      // ダイアログが表示されている場合は何もしない
      if (this.confirmDialog) return;

      // ダイアログを表示
      this.toggleConfirmDialog();
    },
    validateTel() {
      // 電話番号入力の整形
      this.tel = this.tel.replace(/[^\d-]/g, "");
    },
    // 確認ダイアログの表示切り替え
    toggleConfirmDialog() {
      this.confirmDialog = !this.confirmDialog;
    },
    // 結果ダイアログの表示切り替え
    toggleResultDialog() {
      this.resultDialog = !this.resultDialog;
    },

    // お問い合わせ送信の実行
    async executeInquiry() {
      try {
        const response = await APIClient.post("send_mail.php", {
          name: this.fullName,
          mail: this.email,
          tel: this.tel,
          content: this.inquiry,
        });

        console.log(response); // レスポンスをログに出力
        if (response.Status == API_STATUS.NORMAL) {
          this.resultMessage = "送信が完了しました。";
        } else {
          this.resultMessage = "エラーが発生しました。";
        }
      } catch (error) {
        console.error(error); // エラーをログに出力
        // ここでエラー時の処理を追加
        this.resultMessage = "エラーが発生しました。";
      } finally {
        // ダイアログを切替
        this.confirmDialog = false;
        this.toggleResultDialog();
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>
<style scoped>
.contact-container {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
  width: 80%;
}
</style>
