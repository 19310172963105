<template>
  <div class="mypage">
    <div class="container">
      <header>
        <div class="header-elements">
          <h4 class="back" @click="back">＜ 戻る</h4>
          <label>{{ vid }}：{{ vplynm }}</label>
          <button @click="goToSettingKill">キル数入力</button>
        </div>
      </header>
      <div class="record-container">
        <div class="overlay-image yellow-base-img">
          <!-- 重ねる画像 -->
          <img src="images/record_base.svg" />
          <!-- 中央揃えでテキストを表示するための要素 -->
          <div class="overlay-text">
            <p>【戦歴】</p>
          </div>
        </div>
        <div class="image-container">
          <img src="images/record_banner.jpg" class="record-image" />
          <div class="chart-container">
            <canvas id="radarChart"></canvas>
          </div>
        </div>
        <div class="yellow-base-img" style="position: relative">
          <img src="images/record_base.svg" />
          <div class="overlay-text">
            <p>【詳細】</p>
          </div>
        </div>
        <div class="form-group">
          <div class="selecter-element">
            <span>期間</span>
          </div>
          <div class="bars-container">
            <div class="bar nowrap">
              <div class="date-selectors">
                <select
                  v-model="dateFrom['year']"
                  required
                  @change="selectFromYear"
                >
                  <option value="" disabled selected>年</option>
                  <option
                    v-for="year in yearSelecter"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <span>年</span>
                <select
                  v-model="dateFrom['month']"
                  required
                  @change="selectFromMonth"
                >
                  <option value="" disabled selected>月</option>
                  <option
                    v-for="month in monthSelecter"
                    :key="month"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
                <span>月</span>
                <select
                  v-model="dateFrom['day']"
                  required
                  @change="selectFromDay"
                >
                  <option value="" disabled selected>日</option>
                  <option v-for="day in daySelecter" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select>
                <span>日</span>
              </div>
            </div>
            <div class="selecter-element">
              <span>～</span>
            </div>
            <div class="bar nowrap">
              <div class="date-selectors">
                <select
                  v-model="dateTo['year']"
                  required
                  @change="selectToYear"
                >
                  <option value="" disabled selected>年</option>
                  <option
                    v-for="year in yearSelecter"
                    :key="year"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <span>年</span>
                <select
                  v-model="dateTo['month']"
                  required
                  @change="selectToMonth"
                >
                  <option value="" disabled selected>月</option>
                  <option
                    v-for="month in monthSelecter"
                    :key="month"
                    :value="month"
                  >
                    {{ month }}
                  </option>
                </select>
                <span>月</span>
                <select v-model="dateTo['day']" required @change="selectToDay">
                  <option value="" disabled selected>日</option>
                  <option v-for="day in daySelecter" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select>
                <span>日</span>
              </div>
            </div>
          </div>
        </div>
        <div class="record-details">
          <div class="record-element">
            <span class="record-label">キル数：</span>
            <span class="record-data">{{ kills }}</span>
          </div>
          <div class="record-element">
            <span class="record-label">デス数：</span>
            <span class="record-data">{{ deaths }}</span>
          </div>
          <div class="record-element">
            <span class="record-label">K/D比：</span>
            <span class="record-data">{{ kill_rate }}</span>
          </div>
          <div class="record-element">
            <span class="record-label">勝敗比：</span>
            <span class="record-data">{{ win_rate }}</span>
          </div>
          <div class="record-element">
            <span class="record-label">平均生存時間：</span>
            <span class="record-data"
              >{{ truncated_survive_minutes }}分{{
                truncated_survive_seconds
              }}秒</span
            >
          </div>
          <div class="record-element">
            <span class="record-label">ランク指数：</span>
            <span class="record-data">{{ rank_point }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS, MAX_CHART_TICKS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  data() {
    return {
      vid: null,
      vmemnm: null,
      vplynm: null,

      kills: 0,
      deaths: 0,
      kill_rate: 0.0,
      win_rate: 0.0,

      total_kill_rate: 0.0,
      total_kill_death_rate: 0.0,
      total_survive_rate: 0.0,
      average_survive_time: 0.0,
      total_win_rate: 0.0,
      rank_point: 0.0,

      // 営業開始日
      startDate: [],
      // 今日
      endDate: [],
      // 年選択肢
      yearSelecter: [],
      // 月選択肢
      monthSelecter: [],
      // 日選択肢
      daySelecter: [],
      //検索
      dateFrom: { year: 2024, month: 1, day: 1 },
      dateTo: { year: 2024, month: 1, day: 1 },
      originalData: { year: 2024, month: 1, day: 1 },
      chart: null,
    };
  },
  computed: {
    truncated_survive_minutes() {
      return Math.floor(this.average_survive_time / 60);
    },
    truncated_survive_seconds() {
      return Math.floor(this.average_survive_time % 60);
    },
    formattedDate() {
      return `${this.dateFrom.year}-${String(this.dateFrom.month).padStart(
        2,
        "0"
      )}-${String(this.dateFrom.day).padStart(2, "0")}`;
    },
  },
  watch: {},
  mounted() {
    if (getQueryParam("backpress")) {
      this.back();
      return;
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.vid = sessionUserId;
        this.getMemberInfo(sessionUserId, login_hash, login_time);
        this.getSettingDateSelecter();
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.vmemnm = response.Data.vmemnm;
          this.vplynm = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    renderChart() {
      console.log("Rendering chart");
      const ctx = document.getElementById("radarChart").getContext("2d");
      if (this.chart) {
        this.chart.destroy(); // チャートのインスタンスが存在する場合は破棄
      }
      this.chart = new Chart(ctx, {
        type: "radar",
        data: {
          labels: [
            "平均キル数",
            "平均生存時間",
            "K/D比",
            "勝敗比",
            "ランク指数",
          ],
          datasets: [
            {
              label: "プレイヤーステータス",
              data: [
                this.total_kill_rate * MAX_CHART_TICKS,
                this.total_survive_rate * MAX_CHART_TICKS,
                this.total_kill_death_rate * MAX_CHART_TICKS,
                this.total_win_rate * MAX_CHART_TICKS,
                this.rank_point * MAX_CHART_TICKS,
              ],
              backgroundColor: "rgba(194, 50, 227, 0.9)",
              borderColor: "rgba(194, 50, 227, 0.9)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false, // アスペクト比を維持しない
          scales: {
            r: {
              grid: {
                color: "white",
              },
              pointLabels: {
                font: {
                  size: 14, // ラベルのフォントサイズを調整
                },
                color: "white", // ラベルの色を設定
              },
              angleLines: {
                display: true,
                color: "white", // 角度線の色を設定
              },
              backgroundColor: "black", // グリッド線同士の間を黒色で埋める
              max: MAX_CHART_TICKS,
              ticks: {
                display: false, // 中心から伸びる数字を非表示にする
                stepSize: 1, // グリッド線の階層を5段階に設定 (0, 20, 40, 60, 80, 100)
              },
            },
          },
          plugins: {
            legend: {
              display: false, // 凡例を非表示にする
            },
          },
        },
      });
    },
    // 戻る
    back() {
      this.$router.push("/my-page");
    },
    goToSettingKill() {
      window.location.href = "/#/setting-kill-counts";
    },
    async getSettingDateSelecter() {
      try {
        // APIリクエストを実行
        const response = await APIClient.get("getOpenDateFromTo.php");
        if (response.Status == API_STATUS.NORMAL) {
          const res = response.Data.startDate.toString();
          const resYear = parseInt(res.substring(0, 4), 10);
          const resMonth = parseInt(res.substring(4, 6), 10);
          const resDay = parseInt(res.substring(6, 8), 10);
          this.startDate = { year: resYear, month: resMonth, day: resDay };
          this.endDate = await this.getToday();
          await this.getDateSelecter();

          // 最終来店日に変更
          const response2 = await APIClient.get("getLastVisit.php", {
            vid: this.vid,
          });
          if (response2.Status == API_STATUS.NORMAL) {
            const res2 = response2.Data.lastVisitDate.toString();
            const res2Year = parseInt(res2.substring(0, 4), 10);
            const res2Month = parseInt(res2.substring(4, 6), 10);
            const res2Day = parseInt(res2.substring(6, 8), 10);
            this.dateFrom = { year: res2Year, month: res2Month, day: res2Day };
            this.dateTo = { year: res2Year, month: res2Month, day: res2Day };
            await this.getData();
          } else {
            console.error("最終来店日の取得に失敗しました。");
          }
        } else {
          console.error("営業日の取得に失敗しました。");
        }
      } catch (error) {
        console.error("情報の取得に失敗しました。", error);
      }
    },
    async getToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return { year, month, day };
    },
    async getDateSelecter() {
      this.yearSelecter = [];
      for (
        let year = this.startDate["year"];
        year <= this.endDate["year"];
        year++
      ) {
        this.yearSelecter.push(year);
      }
      this.monthSelecter = [];
      for (let month = 1; month <= 12; month++) {
        this.monthSelecter.push(month);
      }
      this.daySelecter = [];
      for (let day = 1; day <= 31; day++) {
        this.daySelecter.push(day);
      }
    },
    async selectFromYear(event) {
      this.dateFrom["year"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateFrom);
    },
    async selectFromMonth(event) {
      this.dateFrom["month"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateFrom);
    },
    async selectFromDay(event) {
      this.dateFrom["day"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateFrom);
    },
    async selectToYear(event) {
      this.dateTo["year"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateTo);
    },
    async selectToMonth(event) {
      this.dateTo["month"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateTo);
    },
    async selectToDay(event) {
      this.dateTo["day"] = parseInt(event.target.value);
      this.changeDateCheck(this.dateTo);
    },
    async changeDateCheck({ day, month, year }) {
      const isValid = await this.isValidDate(day, month, year);
      const isFormat = await Promise.resolve(isValid);
      if (isFormat) {
        await this.getData();
      }
    },
    isValidDate(day, month, year) {
      // month は 1 から 12 なので、Date オブジェクトの month は 0 から始まる
      const date = new Date(year, month - 1, day);

      // 検査対象の日付が正しいかどうかをチェック
      return (
        date.getFullYear() === year &&
        date.getMonth() + 1 === month &&
        date.getDate() === day
      );
    },
    changeFormat({ day, month, year }) {
      return (
        year.toString() +
        month.toString().padStart(2, "0") +
        day.toString().padStart(2, "0")
      );
    },
    async getData() {
      try {
        const response = await APIClient.get("get_record.php", {
          vid: this.vid,
          fromDate: this.changeFormat(this.dateFrom),
          toDate: this.changeFormat(this.dateTo),
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.kills = response.Data.total_nkill;
          this.deaths = response.Data.total_ndeath;
          this.kill_rate = response.Data.kill_death_rate;
          this.win_rate = response.Data.win_rate;
          this.total_kill_rate = response.Data.total_kill_rate;
          this.total_kill_death_rate = response.Data.total_kill_death_rate;
          this.total_survive_rate = response.Data.total_survive_rate;
          this.average_survive_time = response.Data.average_survive_time;
          this.total_win_rate = response.Data.total_win_rate;
          this.rank_point = response.Data.rank_point;
          this.renderChart();
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.back {
  color: white;
  text-align: left;
  cursor: pointer;
  width: fit-content;
}

.back:hover {
  border-bottom: 1px solid #fff; /* 下線を追加 */
}
.record-container {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 700px;
  width: 80%;
}

.record-image {
  width: 100%; /* 画像を幅いっぱいに表示 */
  height: auto; /* アスペクト比を保持しつつ高さを自動調整 */
  margin-bottom: 20px;
}

.record-details {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #414141; /* record-detailsの下にラインを追加 */
}

.record-element {
  padding: 10px 120px 10px 0;
  display: flex;
  color: white;
  justify-content: center;
  border-bottom: 1px solid #414141; /* record-detailsの下にラインを追加 */
}

.record-element span,
.selecter-element span {
  padding: 0;
  font-weight: bold;
}

.record-label {
  text-align: right;
  width: 115px;
}

.record-data {
  text-align: center;
  width: 60px;
  font-weight: bold;
  color: yellow;
}

.image-container {
  position: relative;
  width: 100%;
}

.chart-container {
  position: absolute;
  top: 60%; /* 親要素の中心に配置 */
  left: 50%; /* 親要素の中心に配置 */
  width: 70%; /* キャンバスの幅を小さくする */
  height: 70%; /* キャンバスの高さを小さくする */
  transform: translate(-50%, -50%); /* 中心に配置するための変換 */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* ポインターイベントを無効にして画像の操作を優先 */
}

#radarChart {
  width: 100%;
  height: 100%;
}

.bars-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 5px; */
  /* margin-top: 5px; */
  justify-content: center;
}

.bar {
  padding: 5px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.selecter-element {
  /* padding: 10px 10px 10px 10px; */
  display: flex;
  color: white;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  .image-container {
    margin-top: 40%;
  }

  .record-image {
    visibility: hidden; /* 画像を非表示にする */
  }

  .chart-container {
    top: 45%;
    left: 50%;
    width: 120%;
    height: 120%;
  }

  .bars-container {
    margin-top: 5px;
    flex-direction: column;
  }
}
</style>
