<template>
  <div class="mypage">
    <div class="logout-container">
      <header>
        <div class="header-elements">
          <label>{{ id }}：{{ playerName }}</label>
          <button class="logout-button" @click="logout">ログアウト</button>
        </div>
      </header>
    </div>
    <div class="container">
      <div class="menu-container">
        <!-- E.W.C. -->
        <img src="images/mypage_top.png" alt="E.W.C." class="ewc-image" />

        <!-- お知らせエリア -->
        <div class="announcement">
          <div class="announcement-label">
            <div class="announcement-band">&nbsp;</div>
            <label>お知らせ</label>
          </div>
          <div class="announcement-area">
            <!-- お知らせの表示エリア -->
            <div
              class="announcement-item"
              v-for="(announcement, index) in announcements"
              :key="index"
            >
              <time>{{ announcement.date }}</time>
              <div class="announcement-content-group">
                <div class="announcement-content">
                  <span class="announcement-title">{{
                    announcement.title
                  }}</span
                  ><br />
                  <span>{{ announcement.content }}</span>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div class="blocks">
          <div class="block-bar" @click="goToTeamList">
            <img
              src="images/team_list_bar.svg"
              alt="今日のチーム割り振り"
              class="block-bar-img"
            />
            今日のチーム割り振り
          </div>
          <div class="block qr-code" @click="goToQRCode">
            <img src="images/qr.svg" alt="QRコード" class="block-image" />
          </div>

          <div class="block reservation" @click="goToReservation">
            <img src="images/booking.svg" alt="来店予約" class="block-image" />
          </div>

          <div class="block history" @click="goToHistory">
            <img src="images/record.svg" alt="戦歴" class="block-image" />
          </div>
          <div class="block registration-check" @click="gotoRanking">
            <img
              src="images/ranking_m.svg"
              alt="ランキング情報"
              class="block-image"
            />
          </div>

          <div class="block registration-check" @click="goToMemberRegistration">
            <img
              src="images/regist.svg"
              alt="登録内容確認"
              class="block-image"
            />
          </div>
          <div class="block registration-check" @click="goToPrice">
            <img src="images/price.svg" alt="利用料金" class="block-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
import { checkLoginInfo, logout, getQueryParam } from "@/modules/common";

export default {
  data() {
    return {
      id: null,
      playerName: null,
      announcements: [
        // お知らせデータ
        {
          date: "",
          title: "現在、お知らせはありません。",
          content: "",
        },
      ],
    };
  },
  methods: {
    async initialize() {
      // ログイン状態であればユーザーIDを取得してidに格納し、会員情報を取得して入力欄に格納
      const sessionUserId = localStorage.getItem("sessionUserId");
      const login_hash = localStorage.getItem("login_hash");
      const login_time = localStorage.getItem("login_time");
      if (await checkLoginInfo(sessionUserId, login_hash, login_time)) {
        this.id = sessionUserId;
        this.getMemberInfo(sessionUserId, login_hash, login_time);
        this.getAnnouncementInfo(sessionUserId);
      } else {
        logout();
      }
    },
    async getMemberInfo(vid, login_hash, login_time) {
      try {
        const response = await APIClient.get("get_1member.php", {
          vid: vid,
          login_hash: login_hash,
          login_time: login_time,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.playerName = response.Data.vplynm;
        } else {
          console.error("会員情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("会員情報の取得に失敗しました。", error);
      }
    },
    async getAnnouncementInfo(vid) {
      try {
        const response = await APIClient.get("get_info_list.php", {
          vid: vid,
        });
        if (response.Status == API_STATUS.NORMAL) {
          this.announcements = response.Data.map((item) => ({
            date: item.dstart,
            title: item.vtitle,
            content: item.vinfo,
          }));
        } else {
          console.error("お知らせ情報の取得に失敗しました。");
        }
      } catch (error) {
        console.error("お知らせ情報の取得に失敗しました。", error);
      }
    },
    goToQRCode() {
      window.location.href = "/#/view-qr-code";
    },
    goToReservation() {
      window.location.href = "/#/reserve-select";
    },
    goToHistory() {
      window.location.href = "/#/battle-record";
    },
    goToMemberRegistration() {
      window.location.href = "/#/member-registration";
    },
    goToPrice() {
      window.location.href = "/#/price";
    },
    goToTeamList() {
      window.location.href = "/#/team-list";
    },
    gotoRanking() {
      window.location.href = "/#/ranking-list";
    },
    logout() {
      logout();
    },
  },
  mounted() {
    if (getQueryParam("backpress")) {
      window.location.href = "/#/login-my-page";
      return;
    }

    // URLにパラメータありならログイン入力情報を上書き
    const paramId = getQueryParam("vid");
    if (paramId) {
      localStorage.setItem("sessionUserId", paramId);
    }
    const paramHash = getQueryParam("login_hash");
    if (paramHash) {
      localStorage.setItem("login_hash", paramHash);
    }
    const paramTime = getQueryParam("login_time");
    if (paramTime) {
      localStorage.setItem("login_time", paramTime);
    }

    this.initialize();
  },
};
</script>

<style scoped src="@/assets/css/mypage.css"></style>

<style scoped>
.ewc-image {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.announcement {
  margin: 0px auto;
  max-width: 700px;
  width: 100%;
  color: white;
}

.announcement-label {
  display: flex;
  text-align: left;
}

.announcement-band {
  width: 5px;
  margin-right: 10px;
  height: 100%;
  background-color: yellow;
}

.announcement-area {
  margin-top: 10px;
  background-color: #1f1f1f;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  max-height: 230px; /* 最大表示高さ */
  overflow-y: auto; /* スクロール可能に */
  font-size: 13px;
}

.announcement-item {
  display: flex;
}

.announcement-content-group {
  width: 100%;
  height: 100%;
}

.announcement-content {
  height: 100%;
  width: 100%;
  text-align: left;
  white-space: pre-wrap;
}

.announcement-title {
  font-weight: bold;
}

.announcement-content-group hr {
  border: 0.25px solid #555555;
}

.announcement-item time {
  min-width: 120px; /* より広い横幅に設定 */
  flex: 0 0 auto; /* サイズ変更を禁止 */
}

.blocks {
  margin: 20px auto; /* 画面中央に揃える */
  display: inline-flex; /* 横並びにする */
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
}

.block {
  margin-top: 20px;
  margin-right: 20px;
  width: 200px;
  height: 200px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer; /* マウスをポインターに変更 */
}

.block-bar {
  width: 81%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-right: 1%;
  font-weight: bold;
  cursor: pointer; /* マウスをポインターに変更 */
  position: relative; /* 相対位置指定 */
}

.block-bar-img {
  position: absolute; /* 絶対位置指定 */
  left: 10px; /* 画像を左に配置 */
  top: 50%; /* 縦方向中央揃え */
  transform: translateY(-50%); /* 縦方向中央揃え */
  width: 50px; /* 画像の幅を調整 */
  height: auto;
}

.block-bar-text {
  text-align: center;
}

.block img {
  width: 100%; /* 画像を親要素に合わせる */
  height: 100%;
  border-radius: 20px; /* 画像にもborder-radiusを適用する */
  object-fit: cover; /* アスペクト比を維持しながら画像を親要素にフィット */
}

.logout-container {
  margin-top: 20px;
  text-align: right;
}

.logout-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: white;
  color: black;
}

/* モバイルデバイスに対するメディアクエリ */
@media (max-width: 1023px) {
  .ewc-image {
    width: 30%;
  }

  /* ログアウトボタンのスタイルを指定 */
  .logout-button {
    padding: 8px 16px; /* ボタンのパディングを変更 */
    font-size: 10px; /* ボタンのフォントサイズを変更 */
  }

  .blocks {
    flex-wrap: wrap; /* ブロックを折り返す */
    justify-content: center; /* 中央揃え */
  }

  .block {
    margin-top: 20px; /* 上部のマージンを調整 */
    margin-right: 0; /* マージンをリセット */
    width: calc(50%); /* 2つのブロックを横並びにする */
    height: 100%;
    scale: 90%;
  }

  .block-bar {
    width: 100%;
    margin-bottom: 0;
  }

  .announcement-area {
    max-height: 150px; /* お知らせエリアの高さを制限 */
  }

  .announcement {
    width: 95%;
  }
}
</style>
