<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  text-align: center;
  font-family: "Yu Gothic";
}
</style>
