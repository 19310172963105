import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";

// 日付をyyyyMMdd形式の数値に変換する関数
export function convertDateToNumber(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year * 10000 + month * 100 + day;
}

/**
 * yyyy-MM-dd形式の文字列をyyyy/MM/dd(w)形式に変換する関数
 * @param {string} dateStr - yyyy-MM-dd形式の日付文字列
 * @returns {string} - yyyy/MM/dd(w)形式の日付文字列
 */
export function formatDateWithWeekday(dateStr) {
  // yyyy-MM-dd形式の文字列をDateオブジェクトに変換
  const date = new Date(dateStr);

  // 曜日を日本語で取得
  const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
  const weekday = weekdays[date.getDay()];

  // 日付をyyyy/MM/dd形式にフォーマット
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // フォーマットした文字列を返す
  return `${year}/${month}/${day}(${weekday})`;
}

export function pad(num) {
      return num.toString().padStart(2, '0');
}

export function generateRandomString(length) {
      const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let randomString = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
      }

      return randomString;
  }

export function formatPhoneNumberToE164(phoneNumber, country = 'JP') {
  // 日本の国コードは +81
  const countryCode = country == 'JP' ? "+81" : "";
  
  // ハイフンを取り除く
  let cleaned = phoneNumber.replace(/-/g, "");
  
  // 先頭の "0" を削除
  if (cleaned.startsWith("0")) {
    cleaned = cleaned.substring(1);
  }

  // E.164形式に変換
  const e164PhoneNumber = countryCode + cleaned;
  
  return e164PhoneNumber;
}

export function getQueryParam(name) {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.split("?")[1]);
      return params.get(name);
}
  
export async function checkLoginInfo(vid, login_hash, login_time) {
  try {
    const response = await APIClient.get("verify_login_hash.php", {
      vid,
      login_hash,
      login_time,
    });
    return response.Status == API_STATUS.NORMAL;
  } catch (error) {
    return false;
  }
}

export function logout() {
  localStorage.removeItem("sessionUserId");
  localStorage.removeItem("login_hash");
  localStorage.removeItem("login_time");
  window.location.href = "/#/login-my-page";
}