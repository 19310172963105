<template>
  <div>
    <header class="nerf-header">
      <div class="page-header-content">
        <div class="header-left">
          <img src="images/icon_top.svg" alt="Logo" />
        </div>
        <div class="header-center">
          <span>TEL 088-802-8154</span>
        </div>
        <div class="header-right">
          <img
            v-on:click="goback"
            src="images/nerf_9.svg"
            alt="サバゲーボタン"
          />
        </div>
      </div>
    </header>
    <div class="nerf-content" id="nerf-top">
      <div class="section top-section">
        <img
          class="top-image"
          src="images/nerf_1.svg"
          alt="ナーフスポーツイメージ1"
        />
        <img src="images/nerf_2.jpg" alt="ナーフスポーツイメージ2" />
      </div>

      <div id="information" class="div3" style="position: relative">
        <div class="col" style="width: 30%; float: left">
          <p class="div-title-text">お知らせ</p>
        </div>
        <div class="col" style="width: 70%">
          <ul style="list-style-type: none; width: 70%; margin-top: 70px">
            <li
              v-for="(item, index) in inforItems"
              :key="item.seq"
              :style="{
                'border-top': '2px solid #c3c3c3',
                'text-align': 'left',
                'border-bottom':
                  index === inforItems.length - 1
                    ? '2px solid #c3c3c3'
                    : 'none',
              }"
            >
              <div class="row">
                <p class="black-sub-title-text">{{ item.title }}</p>
              </div>
              <div class="row">
                <p class="info-text" style="">{{ item.date }}</p>
                <p class="info-text" style="">{{ item.content }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        id="information_noinfo"
        class="div3_noinfo fade-in"
        style="position: relative"
      >
        <div class="col" style="width: 30%; float: left">
          <p class="div-title-text" style="margin-top: 10px !important">
            お知らせ
          </p>
        </div>
        <div class="col" style="width: 70%">
          <div class="row">
            <p style="text-align: left" class="black-sub-title-text">
              現在、新しいお知らせはございません
            </p>
          </div>
        </div>
      </div>

      <div class="section">
        <h2><u>ナーフスポーツとは</u></h2>
        <div class="flex-container">
          <img src="images/nerf_3.jpg" alt="スポンジ弾" class="left-img" />
          <p class="text-left">
            ナーフは、アメリカで生まれた本格スポーツシューティングトイです。弾は安全なスポンジ製ソフト弾を使用しており、子どもから大人までスポーツ感覚で本格シューティングが楽しめます。当たっても痛くないので安心です！
          </p>
        </div>
      </div>

      <div class="section">
        <h2><u>ご利用について</u></h2>
        <div class="flex-container">
          <img src="images/nerf_4.jpg" alt="ご利用について" class="left-img" />
          <div class="text-left">
            <p>■ナーフ、ゴーグルは、貸し出しますので手ぶらでOKです！（無料）</p>
            <p>
              ■お一人様から受付可能です！事前予約なしでお気軽に会場にお越しください。
            </p>
            <p>
              ■当施設は、1Fがサバゲー会場。2Fがナーフ会場となっております。<br />（サバゲー会場を見学いただくことも可能です）
            </p>
            <p>■エアコン完備、シャワールーム・更衣室完備、駐車場あり（20台）</p>
            <p>
              ■当日は飲み物・タオルを持参し、動きやすい服装でお越しください。
            </p>
          </div>
        </div>
      </div>

      <div class="section business-hours" style="margin-bottom: 0">
        <h2><u>営業時間・料金</u></h2>
        <img src="images/nerf_5.svg" alt="営業時間・料金" />
      </div>

      <div class="section business-days-contact">
        <h2><u>営業日・お問い合わせ</u></h2>
        <img src="images/nerf_6.svg" alt="営業日" />
        <img src="images/nerf_7.svg" alt="お問い合わせ" />
      </div>
      <div class="section">
        <img src="images/nerf_8.jpg" alt="ナーフスポーツイメージ3" />
      </div>
      <footer id="footer">
        <div class="footer_whitecrow">
          <img src="images/icon_white.svg" alt="RASISグループ" />
          <img src="images/footer_rasis.png" alt="white crow" />
        </div>
        <div class="footer_social">
          <a href="https://x.com/EWC_White_crow" target="_blank"
            ><img src="images/TwitterX_1.png" alt="X"
          /></a>
          <a href="https://www.instagram.com/e.w.c_whitecrow/" target="_blank"
            ><img src="images/instagram_1.png" alt="Instagram"
          /></a>
          <a href="https://www.facebook.com/E.W.C.WhiteCrow" target="_blank"
            ><img src="images/facebook_1.png" alt="Facebook"
          /></a>
          <a
            href="https://www.youtube.com/@whitecrow.rasis-soft"
            target="_blank"
            ><img src="images/youtube_1.png" alt="YouTube"
          /></a>
          <a href="https://tiktok.com/@whitecrow_rasissoft" target="_blank"
            ><img src="images/tiktok_1.png" alt="TikTok"
          /></a>
        </div>
      </footer>
      <img
        src="images/gotop.png"
        v-on:click="goDiv('nerf-top')"
        class="gotop-btn"
      />
    </div>
  </div>

  <div v-if="false" class="div14">
    <div class="sd content background-white height-auto">
      <div class="sd height-auto width-80 image-container">
        <img src="images/Nerf_ComingSoon.svg" class="centered-image" />
        <div class="overlay-text">ナーフサバゲー<br />[近日公開]</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQueryParam } from "@/modules/common";
import APIClient from "@/modules/api";
import { API_STATUS } from "@/modules/constants";
export default {
  data() {
    return {
      test: false,
      inforItems: [],
      lastInfoItem: "",
    };
  },
  name: "NerfView",
  methods: {
    async getInformation() {
      try {
        const response = await APIClient.get("get_info_list.php");
        if (response.Status == API_STATUS.NORMAL) {
          this.inforItems = response.Data.map((item) => ({
            title: item.vtitle,
            content: item.vinfo,
            date: item.dstart,
          }));
          if (this.inforItems.length > 0) {
            document.getElementById("information_noinfo").style.display =
              "none";
          } else {
            document.getElementById("information").style.display = "none";
            document.getElementById("information_noinfo").style.display =
              "block";
          }
        } else {
          console.error("お知らせ情報の取得に失敗しました。");
          document.getElementById("information").style.display = "none";
          document.getElementById("information_noinfo").style.display = "block";
        }
      } catch (error) {
        console.error("お知らせ情報の取得に失敗しました。", error);
        document.getElementById("information").style.display = "none";
        document.getElementById("information_noinfo").style.display = "block";
      }
    },

    goback() {
      this.$router.push("/");
    },
    goDiv(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
  mounted() {
    if (getQueryParam("test")) {
      this.test = true;
    }
    this.getInformation();
  },
};
</script>

<style scoped>
.nerf-header {
  background-color: #fff;
  position: fixed;
  width: 100%;

  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  top: 0px;
  z-index: 10;
}
.page-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-center {
  font-size: 33px;
  font-weight: bolder;
}

.header-right img {
  height: 50px; /* ナーフボタンのサイズを調整 */
  margin-right: 50px;
  cursor: pointer;
}

.nerf-content {
  align-content: center;
  align-items: center;
  background: #fafafa;
  flex-wrap: nowrap;
  height: 860px;
  justify-content: center;
  width: 100%;
  max-width: 100%;
}

.section {
  margin-top: 20px;
}
.section img {
  width: 100%;
  margin-bottom: 10px;
}
.section h2 {
  padding: 10px;
  margin: 0;
  font-weight: bolder;
}
.top-image {
  max-width: 70%;
}
.flex-container {
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  align-self: center;
}
.left-img {
  max-width: 20%;
}
.text-left {
  width: 60%;
  text-align: left;
  margin-left: auto; /* これを追加して右寄せにします */
}
.business-hours {
  background: #f9f9f9;
  padding: 20px;
  text-align: center;
}
.business-hours img {
  width: 95%;
  align-self: center;
}
.business-days-contact img {
  width: 70%;
  align-self: center;
}

@media only screen and (max-width: 1000px) {
  .nerf-header {
    padding: 0 20px;
    height: 50px;
  }
  .top-section {
    margin-top: 20%;
  }
  .page-header-content {
    flex-direction: row;
  }

  .header-left img {
    width: 60px; /* スマホ画面でのロゴとボタンのサイズを調整 */
  }

  .header-right img {
    width: 80px;
    margin-right: 40px;
  }

  .header-center {
    margin: 10px 0;
    font-size: 16px;
  }
  .section h2 {
    font-size: medium;
  }
  .flex-container {
    margin-top: 20px;
  }
  .left-img {
    max-width: 40%;
  }
  .text-left {
    width: 60%;
    text-align: left;
    font-size: 3vw;
    margin-left: 10px;
  }

  .div3 {
    height: 400px;
  }
}
</style>
